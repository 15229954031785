<template>
  <div id='terms-and-conditions-screen' class="base-page">
    <transition
      mode="in-out"
      enter-active-class="animate__animated animate__fadeIn animate__faster"
      leave-active-class="animate__animated animate__fadeOut animate__faster"
    >
      <div v-if="content" class="container webshop-container">
        <h1 class="title">{{ $t('pages.termsAndConditions.title') }}</h1>
        <div class="content-wrapper" v-html="content" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TermsAndConditionsScreen',
  props: {},
  components: {},
  data: () => ({
    content: undefined,
  }),
  created() {
    this.getTermsAndConditions().then((resp) => { this.content = resp.data; });
  },
  computed: {},
  methods: {
    ...mapActions({
      getTermsAndConditions: 'settings/getTermsAndConditions',
    }),
  },
};
</script>

<style lang='scss' scoped>
</style>
